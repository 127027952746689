import React from "react"

import ContentContainer from '../../components/content-container'
import Text from  '../../components/text'

import styles from './component.module.less'

export default function(props) {
  const {before, after, afterText} = (props.children || {});

  return (
      <section className={`${styles.text}${props.className ? ' ' + props.className : ''}`}>
        <ContentContainer className={`${styles.container}${props.containerClassName ? ' ' + props.containerClassName : ''}`}>
          {before && <>{before}</>}

          <Text
              textClassName={props.textClassName}
              center={props.center}
              header={true}
              dark={true}
              title={props.title}
              headline={props.headline}
              headlineType="h1"
              htmlText={props.htmlText}
          >
            {afterText && <>{afterText}</>}
            {!before && !after && <>{props.children}</>}
          </Text>

          {after && <>{after}</>}
        </ContentContainer>
      </section>
  );
}
