import React from "react"
import {graphql} from "gatsby"

import urlParam from '../lib/url-param'
import { vpSearchParamName } from '../lib/search-path'

import Layout from '../layouts/default'

import Header from '../components/header'
import VPSearchResults from '../components/vp-search-results'

import TextHeader from '../content-elements/text-header'

export default ({data, pageContext}) => {

  const templateStrings = data.allDatoCmsTemplateTexte.nodes[0];

  const searchQueryParam = urlParam(vpSearchParamName);
  const query = searchQueryParam ? searchQueryParam.value : '';

  const localeLinks = pageContext.localeLinks.map(l => {
    l.url += `?${vpSearchParamName}=${query}`;
    return l;
  })

  return (
      <Layout
          locale={pageContext.locale}
          localeLinks={localeLinks}
          title={templateStrings.searchresultsTitleLabel}
          robots="noindex,nofollow"
      >
        {{
          header: (
              <Header
                  locale={pageContext.locale}
                  localeLinks={localeLinks}
                  transparent={true}
              >
                <TextHeader
                    center={true}
                    title={templateStrings.vpSearchresultsTitleLabel}
                    headline={templateStrings.vpSearchresultsResultTxt.replace('%query%', query)}
                    headlineType="h1"
                />
              </Header>
          ),
          content: (
              <VPSearchResults
                locale={pageContext.locale}
                items={data.allDatoCmsVertriebspartner.nodes}
                query={query}
              />
          ),
        }}
      </Layout>
  )
}

export const query = graphql`
    query($locale: String!) {
        allDatoCmsTemplateTexte(filter: {locale: {eq: $locale}}) {
            nodes {
                vpSearchresultsTitleLabel
                vpSearchresultsResultTxt
            }
        }
        allDatoCmsVertriebspartner(filter: {locale: {eq: $locale}, name: {ne: null}, zipResponsibility: {ne: ""}}) {
            nodes {
                id
                name
                zipResponsibility
                slug
                image {
                    alt
                    fluid(imgixParams: {w: "200", h: "200", auto: "compress", fit: "crop", crop: "faces", dpr: 1.5, q: 75}) {
                        src
                    }
                }
            }
        }
    }
`
