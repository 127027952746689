import React, {useEffect, useState} from "react"
import {graphql, StaticQuery} from "gatsby";

import Result from './components/result'

import ContentContainer from '../../components/content-container'

import Text from '../text'

import styles from './component.module.less'

export default props => {

  const locale = props.locale || 'de';
  const idLocaleReg = new RegExp(`-${locale}$`);

  const items = (props.items || [])
      .map(n => ({
        ...n,
        contactId: n.id
            .replace(/^DatoCmsVertriebspartner-/, '')
            .replace(idLocaleReg, ''),
      }))
      .map(n => JSON.parse(JSON.stringify(n)));


  const [results, setResults] = useState([]);

  useEffect(() => {
    if (props.query && props.query.length > 0) {
      const r = items.filter(item => {
        const zipAreas = item.zipResponsibility.split('\n');
        return zipAreas.find(za => props.query.startsWith(za)) !== undefined;
      });
      setResults(r);
    } else {
      setResults(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query]);

  return (
      <StaticQuery
          query={graphql`
              query {
                  allDatoCmsConfig {
                      nodes {
                          locale
                          partnerUrlPrefix
                          pageContact {
                            slug
                          }
                          vpSearchFallbackName
                          vpSearchFallbackImage {
                              alt
                              fluid(imgixParams: {w: "200", h: "200", auto: "compress", fit: "crop", crop: "faces", dpr: 1.5, q: 75}) {
                                  src
                              }
                          }
                      }
                  }
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          searchresultsEmptyTxt
                          vpSearchresultsResultIntro
                          vpSearchresultsResultProfileLabel
                          vpSearchresultsResultContactLabel
                      }
                  }
              }
          `}
          render={data => {
            const config = data.allDatoCmsConfig.nodes.find(t => t.locale === locale);
            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);

            return (
                <ContentContainer tag="section" className={styles.vpSearchResults}>
                  { templateStrings.vpSearchresultsResultIntro &&
                    <Text htmlText={templateStrings.vpSearchresultsResultIntro} className={styles.intro} />
                  }

                  <ul className={styles.list}>
                    { results.length === 0 &&
                      <li>
                        {config.vpSearchFallbackName &&
                          <Result
                            name={config.vpSearchFallbackName}
                            image={config.vpSearchFallbackImage}
                            contactUrl={`/${locale}/${config.pageContact.slug}/`}
                            contactLabel={templateStrings.vpSearchresultsResultContactLabel}
                          />
                        }
                        {!config.vpSearchFallbackName &&
                          <>{ templateStrings.searchresultsEmptyTxt }</>
                        }
                      </li>
                    }
                    { results.length > 0 &&
                      <>
                        {results.map((result, i) => (
                            <li key={i}>
                              <Result
                                  name={result.name}
                                  image={result.image}
                                  contactUrl={`/${locale}/${config.pageContact.slug}/#p=${result.contactId}`}
                                  contactLabel={templateStrings.vpSearchresultsResultContactLabel}
                                  profileUrl={ result.slug ? `/${locale}/${config.partnerUrlPrefix ? config.partnerUrlPrefix + '/' : ''}${result.slug}/` : null }
                                  profileLabel={templateStrings.vpSearchresultsResultProfileLabel}
                              />
                            </li>
                        ))}
                      </>
                    }
                  </ul>
                </ContentContainer>
            );
          }}
      />
  )
}
