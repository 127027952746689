import React from "react"

import remoteAssetUrl from "../../../../lib/remote-asset-url";

import styles from './component.module.less'

export default props => {
  const hasImage = props.image && props.image.fluid && props.image.fluid.src;

  return (
      <>
        { hasImage &&
            <img className={styles.resImg} src={remoteAssetUrl(props.image.fluid.src)} alt={props.image.alt} />
        }
        <span className={`${styles.resDetail}${!hasImage ? ' ' + styles.woImg : ''}`}>
          <span className={styles.lbl}>{ props.name }</span>

            { props.profileUrl && props.profileLabel &&
                <a href={props.profileUrl}>
                    { props.profileLabel }
                </a>
            }
            { props.contactUrl && props.contactLabel &&
                <a href={props.contactUrl}>
                    { props.contactLabel }
                </a>
            }
        </span>
      </>
  )
}
